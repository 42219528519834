import { ref, reactive, watch } from "vue";
import moment from 'moment'
import { Moment } from 'moment';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import * as API from "@/API/payment";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
import useFetch from "@/hooks/useFetch";
import {BuItem} from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import {getSubscribedBu} from "@/API/approve";
import downloadFile from '@/utils/payment/downloadFile'
type Key = ColumnProps['key'];

type SearchParams = {
    payoutYear: Moment | null;
    payoutMonth: Moment | null;
    payoutRound?: string;
    entity?: string;
    bu?: string;
    payoutCode: string[];
    payoutStatus: string | undefined;
}

export interface SalesTableData {
    id: string;
    payoutRound: string;
    payoutCode: string;
    payoutAmountWVat: string;
    payoutAmountWOVat: string;
    updatedAt: string;
    status: string;
}

export interface RowSelectionData {
    selectedRowKeys: Key[];
    selectedRowData: SalesTableData[];
}

export interface SalesDownloadTableData {
    value: string;
}

type DownloadParams = {
    payoutRound: string | undefined;
    entity: string | undefined;
}

const useBBACPayment = () => {
    const router = useRouter()
    const store = useStore()
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: SalesTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: SalesTableData[]) => {
          rowSelection.selectedRowKeys = selectedRowKeys;
          rowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '20px'
      })
    const searchParams = reactive<SearchParams>({
        payoutYear: null,
        payoutMonth: null,
        payoutRound: undefined,
        entity: 'BBAC',
        bu: undefined,
        payoutCode: [],
        payoutStatus: undefined
    })
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    // 下拉列表
    const payoutRoundList = ref<string[]>([])
    const payoutCodeList = ref<string[]>([])
    const payoutStatusList = ref<string[]>([])
    const columnsData = [
        { title: "Payout Round", dataIndex: "payoutRound",width: 180},
        { title: "Payout Code", dataIndex: "payoutCode",slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 180},
        { title: "Payout Amount w/o VAT", dataIndex: "payoutAmountWOVat",slots: { customRender: 'payoutAmountWOVat' },  width: 150, align: 'right'},
        { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",slots: { customRender: 'payoutAmountWVat' },  width: 150, align: 'right'},
        { title: "", dataIndex: "blank",width: 20},
        { title: "Last Update Time", dataIndex: "updatedAt", width: 180, key: "updatedAt",align:'center'},
        { title: 'Status', dataIndex: "status", width: 100, align: 'center'}
    ]
    const tableData = ref<SalesTableData[]>()
    const getTableData = () => {
        console.log(searchParams.payoutMonth , 'searchParams.payoutMonth');
        
        const params = {
            year: searchParams.payoutYear?.format('YYYY'),
            month: searchParams.payoutMonth?.format('MM'),
            payoutRound: searchParams.payoutRound ? searchParams.payoutRound : '',
            entity: searchParams.entity ? searchParams.entity : '',
            bu: searchParams.bu ? searchParams.bu : '',
            payoutCodeList: searchParams.payoutCode ? searchParams.payoutCode : [],
            status: searchParams.payoutStatus ? searchParams.payoutStatus : '',
            pageNumber: pageNumber.value,
            pageSize: pageSize.value
        }
        
        API.getSalesOverviewList(params).then(data => {
            tableData.value = data.content
            total.value = data.totalElements
        })
    }
    const doSearch = () => {
        console.log('search按钮')
        // searchParams.payoutRound = searchParams.payoutRound ? searchParams.payoutRound : ""
        // searchParams.entity = searchParams.entity ? searchParams.entity : ""
        // searchParams.bu = searchParams.bu ? searchParams.bu : ""
        // searchParams.payoutCode = searchParams.payoutCode ? searchParams.payoutCode : []
        // searchParams.payoutStatus = searchParams.payoutStatus ? searchParams.payoutStatus : ""
        pageNumber.value = 1
        rowSelection.selectedRowKeys = []
        rowSelection.selectedRowData = []
        getTableData()
    }
    watch(() => searchParams.payoutYear, (value) => {
        if (value) {
            searchParams.payoutMonth?.year(value.year())
        }
    })
    const getInitCondition = () => {
        API.getInitCondition().then(data => {
            payoutStatusList.value = data.salesStatusList
            searchParams.payoutYear = moment().year(data.year)
            searchParams.payoutMonth = moment().year(data.year).month(parseInt(data.month) - 1)
            // payoutStatusList.value = ['Approved']
            // payoutStatusList.value = data.salesStatusList.filter((item: any) => {
            //     return item !== 'Approving'
            // })
           
            // searchParams.payoutStatus = payoutStatusList.value[0]
            doSearch()
        })
    }
    const getRoundListByDate = () => {
        const params = {
            type: 'DEALER',
            year: searchParams.payoutYear?.format('YYYY'),
            month: searchParams.payoutMonth?.format('MM'),
            entity: 'BBAC',
            statusList: payoutStatusList.value
        }
        API.getRoundListByDateAndStatus(params).then((res: any) => {
            payoutRoundList.value = res
        })
        // API.getRoundListByDate({ params }).then(data => {
        //     payoutRoundList.value = Array.from(new Set(data))
        //     // searchParams.payoutRound = ''
        //     // if(data.length > 0) {
        //     //     searchParams.payoutRound = data[0]
        //     // }
        // })
    }
    watch([() => searchParams.payoutYear, () => searchParams.payoutMonth], ([year, month]) => {
        if (year && month) {
            getRoundListByDate()
        }
    })
    const getCodeListByRound = () => {
        const payoutRound = searchParams.payoutRound ? searchParams.payoutRound : ''
        const entity = searchParams.entity ? searchParams.entity : ''
        API.getPayoutCodeListByPayoutRoundAndEntity(payoutRound, entity).then(data => {
            searchParams.payoutCode = []
            payoutCodeList.value = data
        })
    }
    watch(() => searchParams.payoutRound, (payoutRound) => {
        if (payoutRound) {
            getCodeListByRound()
        }
    })
    const doReset = () => {
        Object.assign(searchParams, {
            payoutYear: null,
            payoutMonth: null,
            payoutRound: undefined,
            entity: 'BBAC',
            bu: undefined,
            payoutCode: [],
            payoutStatus: undefined
        })
        payoutRoundList.value = []
        payoutCodeList.value = []
        payoutStatusList.value = []
        tableData.value = []
        getInitCondition()
    }
    const pageChange = (page: number, size: number,rowSeclection: RowSelectionData) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size;
        rowSeclection.selectedRowKeys = []
        getTableData()
    };
    const sizeChange = (page: number, size: number,rowSelection: RowSelectionData) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size;
        rowSelection.selectedRowKeys = []
        getTableData()
    }
    const payoutCodeClick = (record: SalesTableData) => {
        console.log('下载点击的program code对应的payout summary文件' + record)
    }

    // Download Files
    const modalVisible = ref<boolean>(false)
    const modalLoading = ref<boolean>(false)
    const downloadRoundList = ref<string[]>([])
    const downloadParams = reactive<DownloadParams>({
        payoutRound: undefined,
        entity: 'BBAC'
    })
    const modalColumnsData = [
        { title: "File Name", dataIndex: "value"}
    ]
    const modalTableData = ref<SalesDownloadTableData[]>()
    
    const modalRowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: SalesTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: SalesTableData[]) => {
          modalRowSelection.selectedRowKeys = selectedRowKeys;
          modalRowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '20px'
    })
    const getDownloadRoundList = () => {
        const params = {
            type: 'DEALER',
            year: '0',
            month: '0'
        }
        API.getRoundListByDate({ params }).then(data => {
            downloadRoundList.value = Array.from(new Set(data))
            if (searchParams.payoutRound && downloadRoundList.value.indexOf(searchParams.payoutRound) !== -1) {
                downloadParams.payoutRound = searchParams.payoutRound
            } else {
                downloadParams.payoutRound = undefined
            }
        })
    }
    const getModalTableData = () => {
        const entity = 'sales_BBAC'
        API.getDownloadFileList(entity).then(data => {
            modalRowSelection.selectedRowData = []
            modalRowSelection.selectedRowKeys = []
            modalTableData.value = data
        })
    }
    const downloadFilesClick = () => {
      getDownloadRoundList()
      getModalTableData()
      modalVisible.value = true
    }
    watch(() => downloadParams.entity, (val) => {
        if (val) {
            getModalTableData()
        }
    })
    const modalDownloadClick = () => {
        if (!downloadParams.payoutRound) {
            message.warning('Please select Payout Round first')
            return
        }
        if (modalRowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        const params = {
            url: '/ptapi/export/sales/downloadFiles?payoutRound=' + downloadParams.payoutRound + '&entity=' + downloadParams.entity,
            method: 'post',
            params: modalRowSelection.selectedRowKeys
        }
        downloadFile(params).catch(msg =>
            message.warning(msg)
        )
    }
    return {
        searchParams,
        payoutRoundList,
        payoutCodeList,
        payoutStatusList,
        getInitCondition,
        columnsData,
        tableData,
        rowSelection,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        downloadFilesClick,
        payoutCodeClick,
        modalVisible,
        modalLoading,
        modalColumnsData,
        modalTableData,
        modalRowSelection,
        downloadParams,
        downloadRoundList,
        modalDownloadClick
    }
}
export default useBBACPayment